<template>
  <div class="page">
    <Navbar title="家庭成员" type="SMP" />
    <van-form @submit="submit" class="form">
      <van-cell-group>
        <van-field
          v-model="family.name"
          label="姓名"
          placeholder="请输入姓名"
          show-word-limit
          :rules="[{ required: true, message: '请输入姓名' }]"
        />
        <van-field
          v-model="family.relation"
          readonly
          clickable
          name="关系"
          label="关系"
          placeholder="点击选择关系"
          @click="relationShow = true"
        />
        <van-popup :show="relationShow" position="bottom">
          <van-picker
            :columns="relations"
            @confirm="confirmRelation"
            @cancel="relationShow = false"
          />
        </van-popup>
        <van-field
          v-model="family.identity"
          label="身份证号"
          placeholder="请输入身份证号"
          show-word-limit
          :rules="[{ required: true, message: '请输入身份证号' }]"
        />
        <van-field
          v-model="family.phone"
          label="电话号码"
          placeholder="请输入电话号码"
          show-word-limit
          :rules="[{ required: true, message: '请输入电话号码' }]"
        />
        <van-field
          v-model="family.unitName"
          label="工作单位"
          placeholder="请输入单位名称"
          show-word-limit
          :rules="[{ required: true, message: '请输入单位名称' }]"
        /><van-field
          v-model="family.job"
          label="职位"
          placeholder="请输入工作职位"
          show-word-limit
          :rules="[{ required: true, message: '请输入工作职位' }]"
        /> </van-cell-group
    ></van-form>
    <van-row class="subbtn">
      <van-col offset="4" span="16">
        <van-button
          icon="passed"
          size="small"
          color="#00CC99"
          class="btn"
          @click="submit"
        >
          提交保存
        </van-button>
      </van-col>
    </van-row>
    <Loading :show="loadingShow" />
    <Share ref="share" />
  </div>
</template>
<script>
import { Form, CellGroup, Field, Popup, Picker } from 'vant'
import Navbar from '../../common/Navbar.vue'
import Loading from '../../common/Loading.vue'
import Share from '../../common/Share.vue'
export default {
  components: {
    Navbar: Navbar,
    Loading: Loading,
    Share: Share,
    [Form.name]: Form,
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Picker.name]: Picker

  },
  data () {
    return {
      personCode: '',
      seq: '',
      loadingShow: false,
      relationShow: false,
      family: {
        name: '', identity: '', unitName: '', job: '', phone: '', relation: ''
      },
      relations: ['父亲', '母亲', '哥哥', '姐姐', '弟弟', '妹妹', '丈夫', '妻子', '其他']
    }
  },
  mounted () {
    var query = this.$route.query
    this.personCode = query.personCode
    this.seq = query.seq
    this.retrieveFamily()
    this.$refs.share.default('main')
  },
  methods: {
    confirmRelation (val) {
      this.family.relation = val
      this.relationShow = false
    },
    async retrieveFamily () {
      var pd = { personCode: this.personCode, key: 'PSN_FMY_ITEM', type: 'DTL', seq: this.seq }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/retrievePersonAttr', this.$qs.stringify(pd))
      if (res.status === '200') {
        var content = JSON.parse(res.data.content)
        this.family.name = content.name
        this.family.identity = content.identity
        this.family.unitName = content.unitName
        this.family.job = content.job
        this.family.phone = content.phone
        this.family.relation = content.relation
      }
    },
    async submit () {
      var attrs = [
        { key: 'PSN_FMY_ITEM', content: JSON.stringify(this.family), type: 'DTL', seq: this.seq }
      ]
      var pd = { personCode: this.personCode, attrs: JSON.stringify(attrs) }
      const { data: res } = await this.$http.post(this.BMS_URL + '/people/person/editPersonAttr', this.$qs.stringify(pd))
      if (res.status === '200') {
        this.$router.go(-1)
      } else {
        this.$dialog.alert({
          title: '提示信息',
          message: '编辑失败:' + res.msg
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.page {
  text-align: left;
  .subbtn {
    margin: 10px auto;
    text-align: center;
  }
}
</style>
